import React, { useEffect } from "react";
import * as Yup from "yup"
import { parse, isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from "react-table";
import { Table, Pagination, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faWhatsapp,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faStore, faSortUp, faBars, faSortDown, faMapLocation, faAddressCard, faHome, faHouse, faCalendarDays, faUserGroup, faQrcode, faCrown, faRankingStar, faImages, faCalendar, faChalkboardUser, faUser, faUsers, faBell, faCog, faEnvelope, faChartLine, faBook, faCamera, faCogs, faFile, faFolder, faGlobe, faHeart, faImage, faMap, faPaperclip, faPhone, faPlane, faShoppingCart, faSignInAlt, faSignOutAlt, faStar, faTags, faThumbsUp, faTools, faTrophy, faWrench, faBookmark, faBriefcase, faBullhorn, faChartPie, faClipboard, faComment, faCompass, faCopy, faCreditCard, faDatabase, faDollarSign, faDownload, faExclamationCircle, faExternalLinkAlt, faFileAlt, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFilter, faFlag, faFolderOpen, faFont, faGift, faGlobeAfrica, faHdd, faHeadset, faHistory, faInbox, faKey, faLaptop, faLeaf, faLightbulb, faLink, faLock, faLockOpen, faMagic, faMagnifyingGlass, faMapMarkerAlt, faMedal, faMobileAlt, faMoneyBill, faMoneyCheckAlt, faPaintBrush, faPencilAlt, faPercent, faPhoneAlt, faPlay, faPlug, faPlus, faPoll, faPowerOff, faPrint, faQuestion, faReceipt, faRecycle, faRedo, faRocket, faRss, faSave, faSearch, faShare, faShieldAlt, faShoppingBag, faSign, faSlidersH, faSmile, faSort, faSpinner, faStarHalf, faSync, faTabletAlt, faThumbsDown, faTrash, faTv, faUpload, faUserCircle, faVolumeUp, faWifi, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import generateExcel from "zipcelx";

const iconMapping = {
    faMapLocation, faStore, faHome, faBars, faHouse, faCalendar, faCalendarDays, faUserGroup, faCrown, faQrcode, faRankingStar, faImages, faChalkboardUser, faUser, faUsers, faBell, faCog, faEnvelope, faChartLine, faBook, faCamera, faCogs, faFile, faFolder, faGlobe, faHeart, faImage, faMap, faPaperclip, faPhone, faPlane, faShoppingCart, faSignInAlt, faSignOutAlt, faStar, faTags, faThumbsUp, faTools, faTrophy, faWrench, faBookmark, faBriefcase, faBullhorn, faChartPie, faClipboard, faComment, faCompass, faCopy, faCreditCard, faDatabase, faDollarSign, faDownload, faExclamationCircle, faExternalLinkAlt, faFileAlt, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFilter, faFlag, faFolderOpen, faFont, faGift, faGlobeAfrica, faHdd, faHeadset, faHistory, faInbox, faKey, faLaptop, faLeaf, faLightbulb, faLink, faLock, faLockOpen, faMagic, faMagnifyingGlass, faMapMarkerAlt, faMedal, faMobileAlt, faMoneyBill, faMoneyCheckAlt, faPaintBrush, faPencilAlt, faPercent, faPhoneAlt, faPlay, faPlug, faPlus, faPoll, faPowerOff, faPrint, faQuestion, faReceipt, faRecycle, faRedo, faRocket, faRss, faSave, faSearch, faShare, faShieldAlt, faShoppingBag, faSign, faSlidersH, faSmile, faSort, faSpinner, faStarHalf, faSync, faTabletAlt, faThumbsDown, faTrash, faTv, faUpload, faUserCircle, faVolumeUp, faWifi, faWindowClose,
    faFacebook,
    faAddressCard,
    faWhatsapp,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTwitter
};

export function getIcon(iconName) {
    return iconMapping[iconName] || iconMapping.faQuestionCircle;
}

export const url = () => {
    const { search } = window.location
    let paramsURL = new URLSearchParams(search)
    let data = {
        token: paramsURL.get('token'),
        presenter: paramsURL.get('presenter'),
        preview: paramsURL.get('preview')
    }
    return data
}

export const TextWithLineBreaks = ({ text }) => {
    if (!text || typeof myVar === text) {
        return
    }
    const lines = text.split(/\\n|\n/);
    const formattedText = lines.map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index !== text.length - 1 && <br />}
        </React.Fragment>
    ));
    return <span>{formattedText}</span>;
}

export function redirectURL(open, url) {
    if (open === true) {
        window.open(url, '_blank')
    } else {
        window.location.replace(url)
    }
}

//Search debounce
export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };
};

// Date handlers
export function validateDate(date) {
    const parsedDateTime = parse(date, "dd/MM/yyyy", new Date());
    const dateX = new Date(parsedDateTime);
    const result = isValid(dateX);
    if (result === true) {
        return true;
    }
    return false;
}

export function convertDate(date, format = "dd/MM/yyyy HH:mm") {
    const newDate = formatInTimeZone(date, "America/Sao_Paulo", format);
    return newDate
}


export function countStandAloneNumbers(str) {
    if (str === undefined) {
        str = ''
    }
    return str.replace(/\D/g, "")
}

export function validarCPF(cpf) {
    cpf = countStandAloneNumbers(cpf)
    // Verifica se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verifica se todos os dígitos são iguais (CPF inválido, mas passaria na validação do primeiro dígito)
    const digitosIguais = [...new Set(cpf.split(''))].length === 1;
    if (digitosIguais) {
        return false;
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
        return false;
    }

    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
        return false;
    }

    return true; // CPF válido
}

export function cleanUpSpecialChars(str) {
    return str
        .replace(/[ÀÁÂÃÄÅ]/g, "A")
        .replace(/[àáâãäå]/g, "a")
        .replace(/[ÈÉÊË]/g, "E")
        .replace(/[èéêë]/g, "e")
        .replace(/[ÌÍÎÏ]/g, "I")
        .replace(/[ìíîï]/g, "i")
        .replace(/[ÒÓÔÕÖØ]/g, "O")
        .replace(/[òóôõöø]/g, "o")
        .replace(/[ÙÚÛÜ]/g, "U")
        .replace(/[ùúûü]/g, "u")
        .replace(/[^a-z0-9]/gi, ''); // final clean up
}

export function dataURItoBlob(dataURI) {
    // split the data URI into parts
    const parts = dataURI.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

export const handleNotification = async (socket, origin, setNotifications = undefined, setLoading = undefined) => {
    try {
        const permissionResult = await Notification.requestPermission();
        if (permissionResult === 'granted') {
            console.log('Notification permission granted.');
            if (origin === 'perfil' && setNotifications) {
                setLoading(true)
                await toggleSubscribe(socket, setNotifications, setLoading);
            } else {
                await checkAndSubscribeUserToPush(socket);
            }
        } else {
            if (setNotifications) {
                setNotifications(false);
            }
            console.log('Notification permission denied.');
        }
    } catch (error) {
        if (setNotifications) {
            setNotifications(false);
        }
        console.error('An error occurred while requesting notification permission:', error);
    }
};

export const checkAndSubscribeUserToPush = async (socket) => {
    try {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
            socket.emit('subscribe', JSON.stringify(existingSubscription));
        } else {
            // Create a new subscription
            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
            });
            socket.emit('subscribe', JSON.stringify(newSubscription));
            console.log('User is subscribed:', newSubscription);
        }
    } catch (error) {
        console.error('Failed to subscribe the user: ', error);
    }
};

export const useInfiniteScroll = (scrollContainerRef, pageFetching, setPageFetching, pagination, fetchData) => {
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && pageFetching === false && pagination.current > 0) {
                    fetchData(true)
                    setPageFetching(true)
                    pagination.current += 1;
                }
            },
            {
                root: scrollContainer,
                rootMargin: '25px',
                threshold: 1.0,
            }
        );

        const sentinel = document.createElement('div');
        scrollContainer.appendChild(sentinel);
        observer.observe(sentinel);

        return () => {
            observer.unobserve(sentinel);
            scrollContainer.removeChild(sentinel);
        };
    }, [fetchData, pageFetching, pagination, scrollContainerRef, setPageFetching]);
};

export const toggleSubscribe = async (socket, setNotifications, setLoading) => {
    try {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
            console.log('Existing subscription found:', existingSubscription);
            // Unsubscribe the existing subscription
            await existingSubscription.unsubscribe();
            socket.emit('subscribe', null);
            setTimeout(() => {
                setNotifications(false);
                setLoading(false)
            }, 250);
            console.log('Unsubscribed from existing subscription.');
        } else {
            // Create a new subscription
            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
            });
            socket.emit('subscribe', JSON.stringify(newSubscription));
            setTimeout(() => {
                setNotifications(true);
                setLoading(false)
            }, 250);
            console.log('User is subscribed:', newSubscription);
        }
    } catch (error) {
        console.error('Failed to subscribe the user: ', error);
    }
};

export const urlBase64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const onSelectFile = (e, setSelectedFile, notyf) => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }
    const file = e.target.files[0];
    const fileType = e.target.files[0].type;
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(fileType)) {
        notyf.open({
            type: "danger",
            message: "Invalid file type. Please upload an image (gif, jpeg, png).",
            ripple: true,
            dismissible: true,
        });
        return;
    }
    const reader = new FileReader();
    reader.onload = function (readerEvent) {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const MAX_WIDTH = 500;
            const MAX_HEIGHT = 500;
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const dataurl = canvas.toDataURL(fileType);
            const blob = dataURItoBlob(dataurl);
            const resultFile = new File([blob], file.name, { type: fileType });
            setSelectedFile(resultFile);
        };
    };
    reader.readAsDataURL(file);
}

export function schemaCreator(fields, event_id) {
    let json = {}
    fields.forEach((value, key) => {
        let id = value.inputID.toString()
        json[id] = getValidator(value, event_id);
    });
    json['autorization'] = Yup.boolean().oneOf([true], "Aceite os termos para continuar");
    return Yup.object().shape(json);
}

function getValidator(value, event_id) {
    switch (value.validation) {
        case 'text':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
            } else if (value.required) {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
                    .test('required', '', function (valueX) {
                        if (value.dependencies) {
                            if (this.parent[value.dependencies.inputID] !== value.dependencies.value) {
                                return true;
                            }
                        }
                        if (valueX === undefined) {
                            return this.createError({
                                message: "Campo obrigatório"
                            });
                        } else {
                            return true;
                        }
                    });
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'textBig':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("2500", "Máximo de 2500 caracteres")
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("2500", "Máximo de 2500 caracteres")
            } else {
                return Yup.string()
                    .max("2500", "Máximo de 2500 caracteres")
            }
        case 'email':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
                    .email("Email inválido")
            }
        case 'cpf':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("CPF", "CPF inválido", function (valueX) {
                        if (!validarCPF(valueX)) {
                            return false
                        } else {
                            return true
                        }
                    });
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("CPF", "CPF inválido", function (valueX) {
                        if (!validarCPF(valueX)) {
                            return false
                        } else {
                            return true
                        }
                    })
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'telefone':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("telefone", "Telefone inválido", function (valueX) {
                        if (countStandAloneNumbers(valueX).length > 9) {
                            return true
                        } else {
                            return false
                        }
                    });
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
            }
        case 'date':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
            } else if (value.required) {
                return Yup.string()
                    .required("Campo obrigatório")
                    .max("255", "Máximo de 255 caracteres")
                    .test("date", "Data inválida", function (valueX) {
                        if (validateDate(valueX)) {
                            return true
                        } else {
                            return false
                        }
                    });
            } else {
                return Yup.string()
                    .max("255", "Máximo de 255 caracteres")
                    .test("date", "Data inválida", function (valueX) {
                        if (validateDate(valueX)) {
                            return true
                        } else {
                            return false
                        }
                    });
            }
        case 'number':
            if (value.primary) {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .required("Campo obrigatório")
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            } else if (value.required) {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .required("Campo obrigatório")
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            } else {
                return Yup.number().typeError("Valor inválido, apenas números")
                    .test('number', 'Máximo de 255 caracteres', function (valueX) {
                        if (valueX) {
                            if (valueX.toString().length <= 255) {
                                return true
                            }
                        }
                        return false
                    })
            }
        case 'checkbox':
            if (value.primary) {
                return Yup.string()
                    .required("Campo obrigatório")
            } else if (value.required) {
                return Yup.string()
                    .test('required', '', function (valueX) {
                        if (valueX === undefined) {
                            return this.createError({
                                message: "Campo obrigatório"
                            });
                        } else {
                            return true;
                        }
                    });
            } else {
                return Yup.string()
            }

        default:
            return ""
    }
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    // const count = preFilteredRows.length;
    return (
        <Form.Control
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={``}
            className="mt-2"
        />
    );
}

export function filterTableHandle(element) {
    let maxLength = 40;
    let truncatedString = element.label.length > maxLength
        ? element.label.slice(0, maxLength - 3) + "..."
        : element.label;
    switch (element.type) {
        case 'input':
            return {
                Header: truncatedString,
                accessor: element.label,
                filter: "includes",
            }
        case 'number':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: NumberRangeColumnFilter,
                filter: "between",
            }
        case 'select':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: SelectColumnFilter,
                filter: "includes",
            }
        case 'checkbox':
            return {
                Header: truncatedString,
                accessor: element.label,
                Filter: SelectColumnFilter,
                filter: "includes",
            }
        default:
            break;
    }
}

export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div className="d-flex mt-2">
            <Form.Control
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "110px",
                }}
            />
            <span className="mx-2 mt-1">to</span>
            <Form.Control
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "110px",
                }}
            />
        </div>
    );
}

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    return (
        <Form.Select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Form.Select>
    );
}

export const dateFilter = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowDate = new Date(row.values[id]);
        const filterDate = new Date(filterValue);

        // Example: return rows where row date is the same as the filter date
        return rowDate.toDateString() === filterDate.toDateString();
    });
};

export const ColumnSortingTable = ({
    columns,
    data,
    getCellProps,
    title,
    subTitle,
    modal = false,
    setTriggerExport,
    triggerExport,
}) => {
    const filterTypes = React.useMemo(
        () => ({
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
            sortable: false,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetSortBy: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    function getHeader(column) {
        if (column.totalVisibleHeaderCount === 1) {
            return [
                {
                    value: column.Header,
                    type: "string",
                },
            ];
        } else {
            const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
                value: "",
                type: "string",
            }));
            return [
                {
                    value: column.Header,
                    type: "string",
                },
                ...span,
            ];
        }
    }

    function getExcel() {
        const config = {
            filename: title,
            sheet: {
                data: [],
            },
        };

        const dataSet = config.sheet.data;

        // review with one level nested config
        // HEADERS
        headerGroups.forEach((headerGroup) => {
            const headerRow = [];
            if (headerGroup.headers) {
                headerGroup.headers.forEach((column) => {
                    headerRow.push(...getHeader(column));
                });
            }

            dataSet.push(headerRow);
        });

        // FILTERED ROWS
        if (rows.length > 0) {
            rows.forEach((row) => {
                const dataRow = [];

                Object.values(row.values).forEach((value) =>
                    dataRow.push({
                        value,
                        type: typeof value === "number" ? "number" : "string",
                    })
                );

                dataSet.push(dataRow);
            });
        } else {
            dataSet.push([
                {
                    value: "No data",
                    type: "string",
                },
            ]);
        }
        return generateExcel(config);
    }

    useEffect(() => {
        if (triggerExport) {
            getExcel();
            setTriggerExport(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerExport]);

    return (
        <>
            <Table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    {column.canSort && (
                                        <span onClick={(e) => {
                                            e.stopPropagation(); // Prevent click from bubbling up to the th
                                            column.toggleSortBy(); // Manually toggle the sort
                                        }}>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                                                )
                                            ) : (
                                                <FontAwesomeIcon icon={faSort} className="ms-2" />
                                            )}
                                        </span>
                                    )}
                                    <div>
                                        {column.canFilter ? column.render("Filter") : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps([{}, getCellProps(cell)])}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        // Display a message or a row when there are no data
                        <tr>
                            <td className="py-4" colSpan={columns.length} style={{ textAlign: "center" }}>
                                Não foi encontrado nenhum resultado
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Row className="text-start">
                <Col md="6">
                    <span className="mx-2">
                        Página{" "}
                        <span>
                            {pageIndex + 1} de {pageOptions.length}
                        </span>
                    </span>
                    <span className="ms-3 me-2">Mostrar:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md="6">
                    <Pagination className="float-end">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
        </>
    );
};
export function getExcelFromObject({ filename, headers, data }) {
    const config = {
        filename: filename || 'export',
        sheet: {
            data: [],
        },
    };

    const dataSet = config.sheet.data;

    // Add headers to the dataset
    const headerRow = [
        { value: 'E-mail', type: 'string' }, // Email header
        ...headers.map(header => ({
            value: String(header.value),
            type: 'string',
        })),
        { value: 'Contato', type: 'string' }, // Add json_collected.contato
        { value: 'Observação', type: 'string' }, // Add json_collected.observacao
        { value: 'Data', type: 'string' }, // Add collected date
    ];
    dataSet.push(headerRow);

    // Map data to match the headers using inputID
    data.forEach((item) => {
        // Start with the email in the row
        const dataRow = [
            {
                value: item.email || '',
                type: 'string',
            },
        ];

        // Map the other fields based on headers
        dataRow.push(...headers.map((header) => {
            const { inputID } = header;
            const jsonEntry = item.json[inputID];
            return {
                value: jsonEntry ? jsonEntry.value : '',
                type: 'string',
            };
        }));

        dataRow.push({
            value: item.json_collected?.contato || '',
            type: 'string',
        });
        dataRow.push({
            value: item.json_collected?.observacao || '',
            type: 'string',
        });

        // Add the date_collected value
        dataRow.push({
            value: convertDate(item.date_collected),
            type: 'string',
        });

        dataSet.push(dataRow);
    });

    // Generate and download the Excel file
    return generateExcel(config);
}

export function SocialMidiaIcons({ element, index }) {
    let iconMedia
    let url
    switch (element["name"]) {
        case 'facebook':
            iconMedia = faFacebook
            url = element['url']
            break;
        case 'instagram':
            iconMedia = faInstagram
            url = element['url']
            break;
        case 'youtube':
            iconMedia = faYoutube
            url = element['url']
            break;
        case 'linkedin':
            iconMedia = faLinkedin
            url = element['url']
            break;
        case 'twitter':
            iconMedia = faTwitter
            url = element['url']
            break;
        case 'website':
            iconMedia = faGlobe
            url = element['url']
            break;
        case 'whatsapp':
            iconMedia = faWhatsapp
            url = element['url']
            break;
        default:
            break;
    }
    return (
        <FontAwesomeIcon key={index + 'icon'} icon={iconMedia} className="mx-2 iconsHover" size={"xl"} onClick={() => redirectURL(true, url)} />
    )
}
