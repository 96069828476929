import { Helmet } from 'react-helmet-async';
import { useGlobalState } from '../hooks/useInitialization.js';

const HelmetStyle = () => {
  const { dataApplication, error } = useGlobalState();

  const extractRGBA = (rgba) => {
    const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    return match ? `${match[1]},${match[2]},${match[3]},${match[4]}` : '';
  };

  if (!error) {
    return (
      <>
        <Helmet>
          <style>
            {`
          :root {
            --bs-primary-rgb: ${extractRGBA(dataApplication.customizacao.primaryColor)};
            --primaryColor: ${dataApplication.customizacao.primaryColor};
            --primaryColorText: ${dataApplication.customizacao.primaryColorText};
            --bodyColorText: ${dataApplication.customizacao.bodyColorText};
            --borderColor:${dataApplication.customizacao.borderColor};
            --navColorText: ${dataApplication.customizacao.navColorText};
            --navBackgroundColor: ${dataApplication.customizacao.navBackgroundColor};
            --bodyBackgroundColor: ${dataApplication.customizacao.bodyBackgroundColor};
            --fontFamily: ${dataApplication.customizacao.fontFamily};
            --fontFamilySize: ${dataApplication.customizacao.fontFamilySize};
            --logoWidth: ${dataApplication.customizacao.logoWidth};
            --loadingLogoWidth: ${dataApplication.customizacao.loadingLogoWidth};
            --loginImageSide: '${dataApplication.customizacao.login.imageSide.value}%';
            --loginTextSide: '${dataApplication.customizacao.login.textSide.value}%';
            --loginTextSideDirection: ${dataApplication.customizacao.login.imageSide.position === 'start' ? 'row' : 'row-reverse'};
            --textSideBackgroundColor: ${dataApplication.customizacao.login.textSide.backgroundColor};
            --textSideColor: ${dataApplication.customizacao.login.textSide.color};
            --loadingBackgroundColor: ${dataApplication.customizacao.login.loading.backgroundColor};
          }
        `}
          </style>
          {dataApplication.customizacao.background.type === 'image' ? (
            <style>
              {`
              :root {
                --background: url('${dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.background.url}');
              }
            `}
            </style>
          ) : ("")}
          {dataApplication.customizacao.background.type === 'color' ? (
            <style>
              {`
              :root {
                --background: ${dataApplication.customizacao.background.color};
              }
            `}
            </style>
          ) : ("")}
          {dataApplication.customizacao.login.loading.type === 'image' || dataApplication.customizacao.login.loading.type === 'video' ? (
            <style>
              {`
              :root {
                --loadingBackground: ${dataApplication.customizacao.login.loading.backgroundColor};
              }
            `}
            </style>
          ) : ("")}
          {dataApplication.customizacao.login.imageSide.url !== "" ? (
            <style>
              {`
              :root {
                --loginImageSideUrl: url('${dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.login.imageSide.url}');
              }
              `}
            </style>
          ) : ("")}
        </Helmet>
      </>
    )

  }
};

export default HelmetStyle;