import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useGlobalState } from './hooks/useInitialization';
import HelmetStyle from './components/HelmetStyle.js';
import Token from './components/Token.js'
import RouteManager from "./Routes"
import LogoFlex from "./assets/img/logoFlex.svg"
import "./components/i18n";

export const App = () => {
  const { dataApplication, styleVersion } = useGlobalState();
  const [loader, setLoader] = useState(false)

  return (
    <>
      {dataApplication && <HelmetStyle key={styleVersion} />}
      <AnimatePresence mode={'wait'} initial={true}>
        {!loader ? (
          <Initialization key={'Initialization'} setLoader={setLoader}></Initialization>
        ) : (
          <RouteManager key={'Routes'} />
        )}
      </AnimatePresence>
    </>
  )
}

const Initialization = ({ setLoader }) => {
  const { dataApplication, token, setToken, error, setMiniLoader, setError, fontLoaded, setFontLoaded, showTokenScreen, setShowTokenScreen } = useGlobalState();
  const [loaderLogo, setLoaderLogo] = useState(false)
  const [loaderBackground, setLoaderBackground] = useState(false)

  useEffect(() => {
    let time = 1500
    if (dataApplication.customizacao && dataApplication.customizacao.removeLogoFlex) {
      time = 0
    }
    if (dataApplication && loaderLogo && loaderBackground && !error && fontLoaded && token) {
      setTimeout(() => {
        setLoader(true);
      }, time);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderBackground, loaderLogo, error, fontLoaded, token, showTokenScreen])

  useEffect(() => {
    // Pre-load background if exists
    if (dataApplication) {
      if (dataApplication.customizacao.background) {
        switch (dataApplication.customizacao.background.type) {
          case 'image':
            const imageBackground = new Image()
            imageBackground.src = `${dataApplication.customizacao.server.imageServer}/plataforma-eventos/${dataApplication.event_id}/${dataApplication.customizacao.background.url}`;
            imageBackground.onload = function () {
              setLoaderBackground(true)
            }
            imageBackground.onerror = function () {
              setTimeout(() => {
                setMiniLoader(false)
                setError('Evento com recursos inválidos')
              }, 1500);
            };
            break;
          case 'video':
            const videoBackground = document.createElement('video');
            videoBackground.preload = 'auto';
            videoBackground.src = `${dataApplication.customizacao.server.imageServer}/plataforma-eventos/${dataApplication.event_id}/${dataApplication.customizacao.background.url}`;
            videoBackground.onloadeddata = () => {
              setLoaderBackground(true);
            };
            videoBackground.onerror = () => {
              setTimeout(() => {
                setMiniLoader(false)
                setError('Evento com recursos inválidos')
              }, 1500);
            };
            break;
          default:
            setLoaderBackground(true);
        }
      }
      // Pre-load logo if exists
      if (dataApplication.customizacao.logo !== '') {
        const imageLogo = new Image()
        imageLogo.src = dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.logo;
        imageLogo.onload = function () {
          setLoaderLogo(true)
        }
        imageLogo.onerror = function () {
          setTimeout(() => {
            setMiniLoader(false)
            setError('Evento com recursos inválidos')
          }, 1500);
        };
      } else {
        setLoaderLogo(true)
      }
      // Pre-load font if exists
      if (dataApplication.customizacao.fontFamily) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?family=${dataApplication.customizacao.fontFamily.replace(/ /g, '+')}&display=swap`;
        document.head.appendChild(link);
        setFontLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApplication.customizacao])

  if (showTokenScreen || error) {
    return (
      <div className="loaderContainer">
        <motion.div
          className="App-start"
          style={{ backgroundColor: "#4ba700" }}
          key={'TokenScreen'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0 }}>
          <Token setShowTokenScreen={setShowTokenScreen} setLoader={setLoader} setToken={setToken} error={error}></Token>
        </motion.div>
      </div>
    )
  } else {
    return (
      <div className="loaderContainer">
        {dataApplication.customizacao && <motion.div
          className="App-start"
          style={dataApplication.customizacao && !dataApplication.customizacao.removeLogoFlex ? ({ backgroundColor: "#4ba700" }) : ({ backgroundColor: "#fff" })}
          key={'loadingScreen'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0 }}>
          {dataApplication.customizacao && !dataApplication.customizacao.removeLogoFlex ? (<img src={LogoFlex} alt="Logo" className="logo"></img>) : ("")}
        </motion.div>}
      </div>
    )
  }
}