import React from 'react'
import {
    Col,
    Form,
    Row,
} from "react-bootstrap"
import { Formik } from "formik"
import axios from 'axios'
import InputMask from "react-input-mask"
import { schemaCreator } from "./Functions.js"
import { useGlobalState } from '../hooks/useInitialization.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll } from '@fortawesome/free-solid-svg-icons';

export const FormComponent = ({ formikRef, registerUser, editValues = false }) => {
    const { dataApplication } = useGlobalState();

    const getInitialValues = () => {
        let values = {};

        // Add a key for the authorization message if it has a status
        if (dataApplication.customizacao.form.authorizationMessage &&
            dataApplication.customizacao.form.authorizationMessage.status) {
            values.autorization = false;
        }

        dataApplication.customizacao.form.fields.forEach((field, index) => {
            if (field.type === 'select') {
                values[field.inputID] = field.options[0]
            }
            if (field.defaultValue) {
                values[field.inputID] = field.defaultValue;
            }
            if (editValues) {
                Object.entries(editValues).forEach(([key, element]) => {
                    values[key] = element.value
                })
            }
        });
        
        return values;
    };

    const autorizationMessage = () => {
        return (
            <span>
                {dataApplication.customizacao.form.authorizationMessage.value} <a href={dataApplication.customizacao.form.authorizationLink} className="primaryColorT ms-1" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faScroll} size="lg" /></a>
            </span>
        )
    }

    return (
        <Formik
            validationSchema={schemaCreator(dataApplication.customizacao.form.fields, dataApplication.event_id)}
            onSubmit={registerUser}
            innerRef={formikRef}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            initialValues={getInitialValues()}>
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                dirty,
                isSubmitting,
                setSubmitting,
                setFieldValue,
            }) => (
                <Form noValidate onSubmit={handleSubmit} className="text-start">
                    <Row>
                        {Object.keys(dataApplication.customizacao.form.fields).map((key) => {
                            if (dataApplication.customizacao.form.fields[key].dependencies) {
                                const indexV = dataApplication.customizacao.form.fields[key].dependencies.inputID
                                if (values[indexV] !== dataApplication.customizacao.form.fields[key].dependencies.value) { // Adjust the key according to your setup
                                    return ""
                                }
                            }
                            const index = dataApplication.customizacao.form.fields[key].inputID.toString()
                            switch (dataApplication.customizacao.form.fields[key].type) {
                                case 'input':
                                    let mask = false
                                    let placeholder = false
                                    switch (dataApplication.customizacao.form.fields[key].validation) {
                                        case 'telefone':
                                            mask = "(99) 9.9999-9999"
                                            placeholder = "(00) 0.0000-0000"
                                            break;
                                        case 'cpf':
                                            mask = "999.999.999-99"
                                            placeholder = "000.000.000-00"
                                            break;
                                        case 'date':
                                            mask = "99/99/9999"
                                            placeholder = "00/00/0000"
                                            break;

                                        default:
                                            break;
                                    }
                                    if (mask) {
                                        return (
                                            <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'key' + key}>
                                                <Form.Label>{dataApplication.customizacao.form.fields[key].label}</Form.Label>
                                                <InputMask
                                                    placeholder={placeholder}
                                                    mask={mask}
                                                    value={values[index]}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[index]}
                                                    isValid={touched[index] && !errors[index]}
                                                >
                                                    {(inputProps) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            type="text"
                                                            name={index}
                                                        />
                                                    )}
                                                </InputMask>
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[index]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    } else {
                                        return (
                                            <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'key' + key}>
                                                <Form.Label>{dataApplication.customizacao.form.fields[key].label}</Form.Label>
                                                <Form.Control
                                                    placeholder={dataApplication.customizacao.form.fields[key].placeholder}
                                                    type="text"
                                                    name={index}
                                                    value={values[index]}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[index]}
                                                    isValid={touched[index] && !errors[index]}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[index]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }
                                case 'textarea':
                                    return (
                                        <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'key' + key}>
                                            <Form.Label>{dataApplication.customizacao.form.fields[key].label}</Form.Label>
                                            <Form.Control
                                                placeholder={dataApplication.customizacao.form.fields[key].placeholder}
                                                type="text"
                                                as="textarea"
                                                name={index}
                                                value={values[index]}
                                                onChange={handleChange}
                                                isInvalid={!!errors[index]}
                                                isValid={touched[index] && !errors[index]}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors[index]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )
                                case 'select':
                                    return (
                                        <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'key' + key}>
                                            <Form.Label>{dataApplication.customizacao.form.fields[key].label}</Form.Label>
                                            <Form.Select
                                                name={index}
                                                onChange={handleChange}
                                                value={values[index]}
                                                isInvalid={!!errors[index]}
                                                isValid={touched[index] && !errors[index]}
                                            >
                                                <option selected value={null} disabled>Selecione...</option>
                                                {dataApplication.customizacao.form.fields[key].options.map((key) => (
                                                    <option key={"options" + index + key} value={key}>
                                                        {key}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors[index]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )
                                case 'checkbox':
                                    if (dataApplication.customizacao.form.fields[key].options && dataApplication.customizacao.form.fields[key].options.length > 0) {
                                        return (
                                            <Form.Group controlId={'input' + key} className={`mb-2 row mx-0 ${errors[index] ? 'is-invalid' : ''}`} key={'key' + key}>
                                                <Form.Label className="px-0">{dataApplication.customizacao.form.fields[key].label}</Form.Label>
                                                {dataApplication.customizacao.form.fields[key].options.map((option, indexX) => (
                                                    <Form.Check
                                                        className={`checkbox-col mx-0`} // Add 'is-invalid' class if there's an error
                                                        key={'check' + indexX}
                                                        type="checkbox"
                                                        label={option}
                                                        name="options"
                                                        value={values[index]}
                                                        checked={values[index] === option ? true : false}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                index,
                                                                option
                                                            );
                                                        }}>
                                                    </Form.Check>
                                                ))}
                                                {errors[index] && <div className="invalid-feedback d-block">{errors[index]}</div>} {/* Display error message */}
                                            </Form.Group>
                                        );
                                    } else {
                                        return (
                                            <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-2' key={'key' + key}>
                                                <Form.Check
                                                    className="checkboxFontSize"
                                                    checked={values[index] === 'Sim' ? true : false}
                                                    type="checkbox"
                                                    value={values[index]}
                                                    name={index}
                                                    label={dataApplication.customizacao.form.fields[key].label}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            index,
                                                            values[index] === 'Sim' ? 'Não' : 'Sim'
                                                        );
                                                    }}
                                                    isInvalid={!!errors[index]}
                                                    feedback={errors[index]}
                                                    feedbackType="invalid">
                                                </Form.Check>
                                            </Form.Group>
                                        )
                                    }
                                default:
                                    return ""
                            }
                        })}
                        {dataApplication.customizacao.form.authorizationMessage && dataApplication.customizacao.form.authorizationMessage.status &&
                            <Form.Group
                                as={Col}
                                sm="12"
                                className="mb-3"
                                controlId="validationFormik05"
                            >
                                <Form.Check
                                    checked={values.autorization}
                                    className="lgpd checkboxFontSize"
                                    type="checkbox"
                                    name={`autorization`}
                                    label={autorizationMessage()}
                                    onChange={(e) => {
                                        setFieldValue(
                                            `autorization`,
                                            values.autorization === true
                                                ? false
                                                : true
                                        );
                                    }}
                                    value={values.autorization}
                                    isInvalid={!!errors.autorization}
                                    feedback={errors.autorization}
                                    feedbackType="invalid">
                                </Form.Check>
                            </Form.Group>
                        }

                    </Row>
                </Form>
            )}
        </Formik>
    )
}