import { useState, useRef, useEffect, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion"
import {
    MediaPermissionsErrorType,
    requestMediaPermissions
} from 'mic-check';
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp'
import useAuth from "../../hooks/useAuth";
import { useGlobalState } from '../../hooks/useInitialization.js';
import PageLoader from '../../components/PageLoader'
import NotyfContext from "../../contexts/NotyfContext";
import { QrReader } from 'react-qr-reader';
import { QRCodeModal } from '../../components/Modals.js'

const Qrcode = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const [showScan, setShowScan] = useState(item ? item : false);
    const { user } = useAuth()

    if (user.type === 0) {
        return (
            <motion.div className="container qrcodeContainer h-100" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                <AnimatePresence mode="wait" initial={false}>
                    {showScan ? (
                        <ScanType01 setShowScan={setShowScan} addCode={item ? item : false}></ScanType01>
                    ) : (
                        <MyQR setShowScan={setShowScan}></MyQR>
                    )}
                </AnimatePresence >
            </motion.div >
        )
    } else {
        return (
            <motion.div className="container qrcodeContainer h-100" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                <ScanType02 setShowScan={setShowScan}></ScanType02>
            </motion.div >
        )
    }
}

const MyQR = ({ setShowScan }) => {
    const { dataApplication } = useGlobalState();
    const { user } = useAuth();
    const qrCodeRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <motion.div key={'listA'} className="myqrcodecard" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Meu código QR</h5></li>
                    </ol>
                </nav>
            </header>

            <div className="px-3 my-auto text-center">
                <p className="mb-0">Para adicionar, peça para o outro participante escanear o seu código QR</p>
            </div>
            <div className="text-center">
                <QRCode ref={qrCodeRef} className="qrcode-frame01 bg-white" value={`${dataApplication.customizacao.server.eventosApp}?token=${dataApplication.token}&addCode=${window.btoa('contact=' + user.user_id)}`} />
            </div>
            <div className="my-auto text-center">
                <Button variant="primary" onClick={() => setShowScan(true)}>Leitor código QR</Button>
            </div>
        </motion.div>
    )
}

const ScanType01 = ({ setShowScan, addCode = false }) => {
    const { dataApplication } = useGlobalState();
    const { socket } = useAppState()
    const { user } = useAuth()
    const [videoPermission, setVideoPermission] = useState();
    const location = useLocation();
    const [showModal, setShowModal] = useState(true);
    const [updatePermission, setUpdatePermission] = useState(false);
    const [QRcodeMessage, setQRcodeMessage] = useState('Nenhum código encontrado');
    const [pageDataItem, setPageDataItem] = useState()
    const notyf = useContext(NotyfContext);
    const scanning = useRef(false)

    function getAddCode(inputString) {
        const regex = /[?&]addCode=([^&#]*)/;
        const matches = inputString.match(regex);
        if (matches) {
            const addCode = matches[1];
            return decodeURIComponent(addCode);
        }
        return null;
    }

    function handleResult(url, addCode = false) {
        if (scanning.current === false && !pageDataItem) {
            setQRcodeMessage('Lendo código QR...')
            scanning.current = true
            try {
                let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
                let decoded
                if (url === false) {
                    decoded = addCode;
                } else {
                    decoded = getAddCode(url);
                }
                if (url && url.includes('flexinterativa-uep-app.herokuapp.com/r/')) {
                    // New QR code pattern handling
                    let id = url.split('/').pop();
                    if (id.length > 0) {
                        socket.emit('qrcodeScanType01', {
                            type: 'contact',
                            code: id,
                        });
                    } else {
                        setQRcodeMessage('Código inválido');
                        setTimeout(() => {
                            scanning.current = false;
                        }, 2500);
                    }
                } else {
                    if (!base64regex.test(decoded)) {
                        setQRcodeMessage('Código inválido')
                        setTimeout(() => {
                            scanning.current = false
                            return
                        }, 2500);
                    }
                    decoded = window.atob(decoded)
                    let id = decoded.split("=")
                    if (id[1] > 0 && id[1] == user.user_id) {
                        setQRcodeMessage('Código inválido')
                        setTimeout(() => {
                            scanning.current = false
                        }, 2500);
                        return
                    }
                    socket.emit('qrcodeScanType01', {
                        type: id[0],
                        code: id[1],
                    });
                }

            } catch (error) {
                setQRcodeMessage('Código inválido')
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            }
        } else {
            setQRcodeMessage('Código inválido')
            setTimeout(() => {
                scanning.current = false
            }, 2500);
        }
    }

    useEffect(() => {
        requestMediaPermissions({ audio: false, video: true })
            .then(function () {
                setVideoPermission(true);
            })
            .catch(function (err) {
                const { type } = err;
                if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: 'Habilite sua câmera',
                        ripple: true,
                        dismissible: true,
                    });
                    // browser does not have permission to access camera or microphone
                } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                    // user didn't allow app to access camera or microphone
                    setVideoPermission(false)
                    requestMediaPermissions({ audio: false, video: true })
                        .then(function () {
                            setVideoPermission(true);
                        })
                        .catch(function (err) {
                            notyf.open({
                                type: "danger",
                                message: 'Habilite sua câmera',
                                ripple: true,
                                dismissible: true,
                            });
                        });
                } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                    setVideoPermission(true)
                    return
                } else {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: 'Houve algum problema, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                    // not all error types are handled by this library
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePermission])

    useEffect(() => {
        const handleNetworkingResponse = (data) => {
            if (data.response_code) {
                if (data.response_code === 23000) {
                    setQRcodeMessage('Você já adicionou esse código QR');
                } else if (data.response_code === 202) {
                    setQRcodeMessage('Código inválido');
                }
            } else {
                if (data.message) {
                    data.message.json = JSON.parse(data.message.json)
                    setPageDataItem(data);
                    setShowModal(true)
                } else {
                    setQRcodeMessage('Código inválido');
                }
            }
            setTimeout(() => {
                scanning.current = false;
            }, 2500);
        };
        if (socket) {
            if (addCode) {
                handleResult(false, addCode)
            }
            socket.on('qrcodeScanType01', handleNetworkingResponse);
            return () => {
                socket.off('qrcodeScanType01', handleNetworkingResponse);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, addCode]);

    if (videoPermission === undefined) {
        return (
            <motion.div className="pageLoaderContainer" key={'pageLoader'} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width="75" />
            </motion.div>
        )
    }

    return (
        <motion.div key={'listA'} className="myqrcodecard justify-content-center" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <QRCodeModal pageDataItem={pageDataItem} showModal={showModal} setShowModal={setShowModal}></QRCodeModal>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => setShowScan(false))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5 onClick={(() => setShowScan(false))}>Meu código QR</h5></li>
                        <li className="breadcrumb-item"><h5>Leitor</h5></li>
                    </ol>
                </nav>
            </header>
            <div className="my-auto text-center px-3">
                <p className="mb-0">Utilize o leitor para escanear código QR de participantes ou pontos de Interesse</p>
            </div>
            <div>
                <QrReader
                    style={{ width: '100%' }}
                    className="qrcode-frame02 my-3 mx-auto"
                    delay={1500}
                    constraints={{
                        facingMode: 'environment'
                    }}
                    onResult={(result, error) => {
                        if (!scanning.current) {
                            if (!!result && result !== undefined) {
                                handleResult(result?.text)
                            } else {
                                setQRcodeMessage('Nenhum código encontrado')
                            }
                        }
                    }}
                />
            </div>
            < div className="qrcode-feedback my-auto mx-auto vertically-centered">
                <p className="mb-0">{QRcodeMessage}</p>
            </div>
        </motion.div>
    )
}

const ScanType02 = ({ setShowScan, addCode = false }) => {
    const { dataApplication } = useGlobalState();
    const { socket, dispatch } = useAppState()
    const { user } = useAuth()
    const [videoPermission, setVideoPermission] = useState();
    const location = useLocation();
    const [showModal, setShowModal] = useState(true);
    const [updatePermission, setUpdatePermission] = useState(false);
    const navigate = useNavigate();
    const [QRcodeMessage, setQRcodeMessage] = useState('Nenhum código encontrado');
    const [pageDataItem, setPageDataItem] = useState()
    const notyf = useContext(NotyfContext);
    const scanning = useRef(false)

    function getAddCode(inputString) {
        const regex = /[?&]addCode=([^&#]*)/;
        const matches = inputString.match(regex);
        if (matches) {
            const addCode = matches[1];
            return decodeURIComponent(addCode);
        }
        return null;
    }

    function handleResult(url, addCode = false) {
        if (scanning.current === false && !pageDataItem) {
            setQRcodeMessage('Lendo código QR...')
            scanning.current = true
            try {
                let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
                let decoded
                if (url === false) {
                    decoded = addCode;
                } else {
                    decoded = getAddCode(url);
                }
                if (url && (url.includes('eventos.flexinterativa.com.br') || url.includes('eventos-dev.flexinterativa.com.br'))) {
                    if (!base64regex.test(decoded)) {
                        setQRcodeMessage('Código inválido')
                        setTimeout(() => {
                            scanning.current = false
                            return
                        }, 2500);
                    }
                    decoded = window.atob(decoded)
                    let id = decoded.split("=")
                    if (id[1] > 0 && id[1] == user.user_id) {
                        setQRcodeMessage('Código inválido')
                        setTimeout(() => {
                            scanning.current = false
                        }, 2500);
                        return
                    }
                    socket.emit('qrcodeScanType02', {
                        type: 'lead',
                        code: id[1],
                    });
                } else {
                    // New QR code pattern handling
                    let id = url.split('/').pop();

                    if (id.length > 0) {
                        socket.emit('qrcodeScanType02', {
                            type: 'lead',
                            code: id,
                        });
                    } else {
                        setQRcodeMessage('Código inválido');
                        setTimeout(() => {
                            scanning.current = false;
                        }, 2500);
                    }
                }
            } catch (error) {
                setQRcodeMessage('Código inválido')
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            }
        } else {
            setQRcodeMessage('Código inválido')
            setTimeout(() => {
                scanning.current = false
            }, 2500);
        }
    }

    useEffect(() => {
        requestMediaPermissions({ audio: false, video: true })
            .then(function () {
                setVideoPermission(true);
            })
            .catch(function (err) {
                const { type } = err;
                if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: 'Habilite sua câmera',
                        ripple: true,
                        dismissible: true,
                    });
                    // browser does not have permission to access camera or microphone
                } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                    // user didn't allow app to access camera or microphone
                    setVideoPermission(false)
                    requestMediaPermissions({ audio: false, video: true })
                        .then(function () {
                            setVideoPermission(true);
                        })
                        .catch(function (err) {
                            notyf.open({
                                type: "danger",
                                message: 'Habilite sua câmera',
                                ripple: true,
                                dismissible: true,
                            });
                        });
                } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                    setVideoPermission(true)
                    return
                } else {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: 'Houve algum problema, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                    // not all error types are handled by this library
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePermission])

    useEffect(() => {
        const handleLeadCollectionResponse = (data) => {
            if (data.response_code) {
                if (data.response_code === 23000) {
                    setQRcodeMessage('Você já adicionou esse código QR');
                } else if (data.response_code === 202) {
                    setQRcodeMessage('Código inválido');
                }
            } else {
                if (data.message) {
                    data.message.json = JSON.parse(data.message.json)
                    setPageDataItem(data);
                    setShowModal(true)
                } else {
                    setQRcodeMessage('Código inválido');
                }
            }
            setTimeout(() => {
                scanning.current = false;
            }, 2500);
        };
        if (socket) {
            if (addCode) {
                handleResult(false, addCode)
            }
            socket.on('qrcodeScanType02', handleLeadCollectionResponse);
            return () => {
                socket.off('qrcodeScanType02', handleLeadCollectionResponse);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, addCode]);

    if (videoPermission === undefined) {
        return (
            <motion.div className="pageLoaderContainer" key={'pageLoader'} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width="75" />
            </motion.div>
        )
    }

    return (
        <motion.div key={'listA'} className="myqrcodecard justify-content-center" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <QRCodeModal pageDataItem={pageDataItem} showModal={showModal} setShowModal={setShowModal}></QRCodeModal>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Leitor QR</h5></li>
                    </ol>
                </nav>
            </header>
            <div className="my-auto text-center px-3">
                <p className="mb-0">Utilize o leitor para escanear código QR dos participantes</p>
            </div>
            <div>
                <QrReader
                    style={{ width: '100%' }}
                    className="qrcode-frame02 my-3 mx-auto"
                    delay={1500}
                    constraints={{
                        facingMode: 'environment'
                    }}
                    onResult={(result, error) => {
                        if (!scanning.current) {
                            if (!!result && result !== undefined) {
                                handleResult(result?.text)
                            } else {
                                setQRcodeMessage('Nenhum código encontrado')
                            }
                        }
                    }}
                />
            </div>
            < div className="qrcode-feedback my-auto mx-auto vertically-centered">
                <p className="mb-0">{QRcodeMessage}</p>
            </div>
        </motion.div>
    )
}

export default Qrcode